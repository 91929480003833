import styled from '@emotion/styled';
import {
  ProductModel,
  ProductPropertyModel,
  ProductPropertyValueModel,
  ReviewModel,
  VariantModel,
} from '@nimles/models';
import { PricelistPublicModel } from '@nimles/models/dist/public/ecommerce';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Column,
  Container,
  Flex,
  Row,
  Section,
} from '@nimles/react-web-components';
import React from 'react';
import siemens from '../../../images/alltomgrill/siemens.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ReviewWithProduct } from '../../../types';

const AffiliateLogo = styled.img`
  width: 100%;
`;

interface ArgumentProps {
  sign: 'pro' | 'con';
  text: string;
}

const StyledArgument = styled.div`
  svg {
    margin-right: 10px;
  }
`;

const Argument = ({ sign, text }: ArgumentProps) => (
  <StyledArgument>
    {sign === 'pro' ? <FontAwesomeIcon icon={faCheck} color="#6CD659" /> : null}
    {sign === 'con' ? <FontAwesomeIcon icon={faTimes} color="#E37373" /> : null}
    {text}
  </StyledArgument>
);

const ProductPropertyName = styled.h4`
  width: 200px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const getPropertyValue = (
  productProperty: ProductPropertyModel,
  productPropertyValue: ProductPropertyValueModel
) => {
  switch (productProperty.propertyType) {
    case 'Number':
      return productPropertyValue.numberValue;
    default:
      return productPropertyValue.stringValue;
  }
};

interface ProductPropertyProps {
  productProperties: ProductPropertyModel[];
  productPropertyValue: ProductPropertyValueModel;
}

const ProductProperty = ({
  productProperties,
  productPropertyValue,
}: ProductPropertyProps) => {
  const productProperty = productProperties?.find(
    ({ id }) => productPropertyValue.productPropertyId === id
  );
  return (
    <Row>
      <Column>
        <ProductPropertyName>{productProperty?.name}</ProductPropertyName>
      </Column>
      <Column>{getPropertyValue(productProperty, productPropertyValue)}</Column>
    </Row>
  );
};

interface Props {
  review: ReviewWithProduct;
  productProperties: ProductPropertyModel[];
  pricelists: PricelistPublicModel[];
  location: Location;
  variant: VariantModel;
}

export const ReviewPage = ({ review, productProperties }: Props) => {
  const affiliates = [{}, {}, {}, {}];
  const product = review.product;

  return (
    <>
      <Section backgroundColor="#075384" color="#fff">
        <Container minHeight="30vh" justify="center" padding="100px 15px">
          <h1>{review.title}</h1>
          <p>{review.summary}</p>
        </Container>
      </Section>
      <Section>
        <Container padding="100px 15px">
          <Row wrap="wrap" alignItems="center">
            <Column xs={100} md={50}>
              <div dangerouslySetInnerHTML={{ __html: review.description }} />
            </Column>
            <Column xs={100} md={50}>
              {affiliates.map(() => (
                <Card>
                  <CardBody>
                    <Flex container alignItems="center" wrap="wrap">
                      <Flex item xs={'0 0 127px'}>
                        <AffiliateLogo src={siemens} alt="Siemens" />
                      </Flex>
                      <Flex item xs="flex">
                        Elgiganten.se
                      </Flex>
                      <Flex item>
                        <Button primary raised>
                          Se priset
                        </Button>
                      </Flex>
                    </Flex>
                  </CardBody>
                </Card>
              ))}
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container padding="100px 15px">
          <Row wrap="wrap" alignItems="center">
            <Column xs={100} md={50}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h3>Specifikationer</h3>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {product.properties?.map((productPropertyValue) => (
                    <ProductProperty
                      productProperties={productProperties}
                      productPropertyValue={productPropertyValue}
                    />
                  ))}
                </CardBody>
              </Card>
            </Column>
            <Column xs={100} md={50}>
              <h2>{product.name}</h2>
              <div dangerouslySetInnerHTML={{ __html: product.summary }} />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container padding="100px 15px">
          <Row wrap="wrap" alignItems="center">
            <Column xs={100} md={50}>
              <div
                dangerouslySetInnerHTML={{ __html: review.score.comment.value }}
              />
            </Column>
            <Column xs={100} md={50}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h3>Positivt</h3>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {review.pros?.map(({ value }) => (
                    <Argument sign="pro" text={value} />
                  ))}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h3>Negativt</h3>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {review.cons?.map(({ value }) => (
                    <Argument sign="con" text={value} />
                  ))}
                </CardBody>
              </Card>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row wrap="wrap">
            <Column xs={100} md={50} lg={40} justify="center"></Column>
          </Row>
        </Container>
      </Section>
    </>
  );
};
