import React from 'react';
import Layout from '../layouts/Layout';
import styled from '@emotion/styled';
import {
  PricelistModel,
  ProductModel,
  ProductPropertyModel,
  ReviewModel,
  VariantModel,
} from '@nimles/models';
import { ReviewPage } from '../components/review/page/ReviewPage';
import { graphql } from 'gatsby';
import { ReviewWithProduct } from '../types';

const Logo = styled.img`
  margin-bottom: 30px;
  width: 120px;
`;

const Review = ({ pageContext, location }) => {
  const { href } = location;
  const {
    review,
    productProperties,
    pricelists,
    variant,
  }: {
    review: ReviewWithProduct;
    productProperties: ProductPropertyModel[];
    pricelists: PricelistModel[];
    variant: VariantModel;
  } = pageContext;
  const { id, title, summary, description, tags, imageIds } = review;

  // const banner = getImageUrl(bannerId, 1920);

  const meta = {
    title,
    description: summary,
    // image: banner,
    keywords: tags?.join(', '),
  };

  return (
    <Layout
      header
      footer
      meta={meta}
      location={location}
      headerAlwaysOpen={true}
    >
      <ReviewPage
        review={review}
        pricelists={pricelists}
        productProperties={productProperties}
        variant={variant}
        location={location}
      />
    </Layout>
  );
};

export default Review;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
